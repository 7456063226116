html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  font: inherit;
  vertical-align: baseline;
}

html {
  font-family: Arial, sans-serif;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.15;
  /* This will prevent the auto font scaling in mobile landscape */
  -webkit-text-size-adjust: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}
blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  display: block;
  border-style: none;
}

a {
  color: inherit;
  background-color: transparent;
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
}

b,
strong {
  font-weight: bold;
}

i,
em {
  font-style: italic;
}

input,
button,
textarea,
select,
optgroup {
  margin: 0;
  font-size: 100%;
  font: inherit;
  line-height: 1.15;
  border: 0 none;
  background: transparent;
}

button {
  color: inherit;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

textarea {
  overflow: auto;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"]::-webkit-search-decoration,
input[type="email"],
input[type="url"],
textarea {
  -webkit-appearance: none;
}

input::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

summary {
  display: list-item;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

@media screen and (min-width: 0) {
  html {
    font-size: 10px;
  }
  body {
    font-size: 2rem;
  }
}

.actionbar {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem 2rem;
  position: relative;
  z-index: 1;
}

.actionbar .wrapper {
  padding: 0;
}

.actionbar ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionbar .button {
  display: block;
  font-size: 1.4rem;
  padding: 1rem 1.5rem;
}
.actionbar .button img {
  width: 4rem;
  filter: invert(1);
  pointer-events: none;
}

.actionbar .button-checkin[aria-expanded="true"] {
  background: var(--color-grey-light);
  color: var(--color-black);
}

.actionbar-popover {
  width: 25rem;
  padding: 2rem 3rem;
  position: absolute;
  z-index: 2;
  bottom: 6rem;
  left: 50%;
  background: var(--color-grey-light);
  border-radius: 0.5rem;
  transform: translate3d(-50%, 0, 0);
}
.actionbar-popover[aria-hidden="true"] {
  display: none;
}
.actionbar-popover::after {
  width: 0;
  height: 0;
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: -3.25rem;
  border: 2rem solid transparent;
  border-top-color: var(--color-grey-light);
  transform: translate3d(-50%, 0, 0);
  pointer-events: none;
  content: "";
}
@media (prefers-color-scheme: dark) {
  .actionbar .button img {
    filter: none;
  }
  .actionbar .button-checkin[aria-expanded="true"] {
    background: var(--color-grey-dark);
    color: var(--color-white);
  }
  .actionbar-popover {
    background: var(--color-grey-dark);
  }
  .actionbar-popover::after {
    border-top-color: var(--color-grey-dark);
  }

}

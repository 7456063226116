html {
  --color-black: #111;
  --color-white: #eee;
  --color-grey-dark: #444;
  --color-grey-light: #bbb;
  --color-green-light: #84D270;
  --color-green-dark: rgb(95, 153, 80);
  --color-red-light: #F36B6B;
  --color-red-dark: rgb(174, 73, 73);
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif;
  color: var(--color-black);
  background-color: var(--color-white);
}
body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 32rem;
  font-size: 2rem;
}
@media screen and (max-width: 374px) {
  body {
    font-size: 1.5rem;
  }
}

@media (prefers-color-scheme: dark) {
  html,
  body {
    color: var(--color-white);
    background-color: var(--color-black);
  }
}

.main {
  flex: 1 1 0;
  margin: 4rem 0;
}
.main.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0;
}

.wrapper {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 2rem;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: 800;
}

h1, .h1 {
  margin-bottom: 2rem;
  font-size: 3.4rem;
  line-height: 1.25;
}
h2, .h2 {
  margin-bottom: 1.5rem;
  font-size: 3rem;
}
h3, .h3 {
  margin-bottom: 1rem;
  font-size: 2.8rem;
}
h4, .h4 {
  margin-bottom: 1rem;
  font-size: 2.4rem;
}

.content p,
.content ul,
.content ol,
.content li {
  margin-bottom: 2rem;
  line-height: 1.35;
}
.content li {
  margin-bottom: 1.5rem;
}
.content ul {
  margin-left: 2rem;
  list-style: disc outside;
}
.content ol {
  margin-left: 3rem;
  list-style: numeric outside;
}
.content .lead {
  margin-bottom: 3rem;
  font-size: 2.5rem;
  line-height: 1.25;
}

.button {
  padding: 1rem 2rem;
  color: var(--color-white);
  font-weight: 600;
  text-decoration: none;
  background-color: var(--color-black);
  border-radius: 2rem;
  transition: background-color 250ms ease-out;
}
.button:hover {
  text-decoration: underline;
  background-color: var(--color-grey-dark);
}

@media (prefers-color-scheme: dark) {
  .button {
    color: var(--color-black);
    background-color: var(--color-white);
  }
  .button:hover {
    background-color: var(--color-grey-light);
  }
}

.hidden {
  display: none;
}

.auth .logged-in.hidden {
  display: none;
}

.auth .logged-out.hidden {
  display: none;
}

#debug {
  box-sizing: border-box;
  width: 100%;
  padding: 2rem;
  font-family: "Courier New", Courier, monospace;
  font-size: 1.5rem;
  line-height: 1.5;
  word-break: break-word;
  overflow: auto;
}
#debug li {
  padding-bottom: 3rem;
}
@media screen and (min-width: 768px) {
  #debug {
    white-space: pre;
  }
}

.families {

}

.families-item {

}

.families-item .wrapper {
  position: relative;
}
.families-item-add {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 2rem;
}

.family-add {

}
.family-add-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.family-add-label {
  display: none;
}
.family-add .button {
  padding: 1rem 1.5rem;
  font-size: 1.4rem;
}
.family-add-members {
  margin: 4rem 0;
}
.family-add-members li {
  margin-bottom: 2rem;
}
.family-add-input {
  box-sizing: border-box;
  width: 100%;
  height: 6rem;
  margin: 0;
  padding: 0 1rem;
  color: var(--color-black);
  text-align: left;
  border: 1px solid var(--color-grey-dark);
  border-radius: 0.5rem;
}
@media (prefers-color-scheme: dark) {
  .family-add-input {
    color: var(--color-white);
  }
}

.family {
  margin-top: 4rem;
}

.family-member {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 1rem 0;
  padding: 1rem;
  border: 2px solid transparent;
  border-radius: 0.75rem;
}
.family-member.red {
  border-color: var(--color-red-dark);
}
.family-member.green {
  border-color: var(--color-green-dark);
}
@media (prefers-color-scheme: dark) {
  .family-member.red {
    border-color: var(--color-red-light);
  }
  .family-member.green {
    border-color: var(--color-green-light);
  }
}

.family-member-avatar {
  width: 6rem;
  height: 6rem;
  position: relative;
  z-index: 2;
  background-color: var(--color-grey-light);
  border: 2px solid var(--color-grey-dark);
  border-radius: 50%;
  overflow: hidden;
}
@media (prefers-color-scheme: dark) {
  .family-member-avatar {
    background-color: var(--color-grey-dark);
    border: 2px solid var(--color-grey-light);
  }
}

.family-member-toggle {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  font-size: 2.2rem;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .family-member-toggle {
    font-size: 3rem;
  }
}

.family-member-status {
  position: relative;
  z-index: 2;
  margin-right: 1rem;
}
.family-member-status img {
  width: 4rem;
}
@media (prefers-color-scheme: dark) {
  .family-member-status img {
    filter: invert(1);
  }
}

.family-member-bar {
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  /* height: calc(100% - 0.6rem); */
  /* margin: 0.3rem; */
  border-radius: 0.5rem;
  transition: width 250ms ease-in-out;
}
.family-member-bar.red {
  background-color: var(--color-red-light);
}
.family-member-bar.green {
  background-color: var(--color-green-light);
}
@media (prefers-color-scheme: dark) {
  .family-member-bar.red {
    background-color: var(--color-red-dark);
  }
  .family-member-bar.green {
    background-color: var(--color-green-dark);
  }
}

.family-member-popover {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 50rem;
  position: absolute;
  z-index: 4;
  left: 50%;
  padding: 2rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  background-color: var(--color-grey-light);
  transform: translate3d(-50%, 0, 0);
}
.family-member-popover[aria-hidden="true"] {
  display: none;
}
.family-member-popover::after {
  width: 0;
  height: 0;
  position: absolute;
  z-index: 3;
  left: 50%;
  border: 2rem solid transparent;
  transform: translate3d(-50%, 0, 0);
  content: "";
}
.family-member-popover.above {
  bottom: calc(100% + 1rem);
}
.family-member-popover.above::after {
  bottom: -3.5rem;
  border-top-color: var(--color-grey-light);
}
.family-member-popover.below {
  top: calc(100% + 1rem);
}
.family-member-popover.below::after {
  top: -3.5rem;
  border-bottom-color: var(--color-grey-light);
}
.family-member-popover b {
  display: block;
}
@media screen and (min-width: 768px) {
  .family-member-popover {
    padding: 4rem;
  }
}
@media (prefers-color-scheme: dark) {
  .family-member-popover {
    background-color: var(--color-grey-dark);
  }
  .family-member-popover.above::after {
    border-top-color: var(--color-grey-dark);
  }
  .family-member-popover.below::after {
    border-bottom-color: var(--color-grey-dark);
  }
}

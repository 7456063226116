.header .wrapper,
.header-nav ul,
.header-auth {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 0;
  min-height: 6rem;
}
.header-nav ul {
  display: none;
  flex: 1 1 0;
  align-items: center;
  justify-content: center;
  list-style: none;
}
.header-nav li {
  margin: 0 0.5rem 0 0;
}
.header-nav a {
  display: block;
  box-sizing: border-box;
  width: 100vw;
  padding: 2rem;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
}
.header-nav a:hover {
  text-decoration: underline;
}

.header-logo {
  position: relative;
  z-index: 5;
  margin: 1rem 0;
  border-radius: 50%;
  overflow: hidden;
}
.header-logo img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  font-size: 1rem;
}

.header-auth span {
  display: inline-block;
  padding: 0 1rem;
}
[data-auth] {
  display: none
}
.header[data-authenticated="true"] [data-auth="true"],
.header[data-authenticated="false"] [data-auth="false"] {
  display: flex;
  align-items: center;
}

.header-toggle {
  position: absolute;
  z-index: 5;
  top: 1rem;
  right: 2rem;
  padding: 0;
}
.header-toggle img {
  display: none;
  width: 6rem;
  height: 6rem;
}
.header-toggle[data-active="false"] img[data-active="false"],
.header-toggle[data-active="true"] img[data-active="true"] {
  display: block;
}
@media (prefers-color-scheme: dark) {
  .header-toggle img {
    filter: invert(1);
  }
}

@media screen and (max-width: 767px) {
  .header-nav[data-active="true"] {
    flex-direction: column;
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: var(--color-white);
  }
  .header-nav[data-active="true"] ul {
    display: flex;
    flex-direction: column;
    margin-top: 10rem;
  }
  .header-nav[data-active="true"] .header-auth {
    display: flex;
    align-items: center;
    margin: 0 0 10rem;
  }
  .header[data-authenticated="true"] .header-nav[data-active="false"] .header-auth {
    display: none;
  }
  .header-auth span {
    text-indent: -123rem;
    overflow: hidden;
  }
  .header[data-authenticated="false"] .header-toggle {
    display: none;
  }
}

@media (prefers-color-scheme: dark) and (max-width: 767px) {
  .header-nav[data-active="true"] {
    background-color: var(--color-black);
  }
}

@media screen and (min-width: 768px) {
  .header-logo img {
    width: 8rem;
    height: 8rem;
  }

  .header-nav {
    justify-content: space-between;
  }
  .header-nav ul {
    display: flex;
  }
  .header-nav a {
    width: auto;
    padding: 1rem;
  }

  .header-auth {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .header-toggle {
    display: none;
  }
}

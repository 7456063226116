.carousel {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  opacity: 0;
}
.carousel::-webkit-scrollbar {
  display: none;
}
.carousel.active {
  opacity: 1;
}
.carousel h1,
.carousel .h1 {
  padding-right: 4rem;
  text-align: center;
}

.carousel ul[data-carousel-wrapper] {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  gap: 0;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
}

.carousel li[data-carousel-item] {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  padding-top: 6rem;
  scroll-snap-align: start;
}

.carousel li[data-carousel-item] .wrapper {
  box-sizing: border-box;
  width: 100%;
}


.carousel .pagination {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: var(--color-white);
}

.carousel .pagination .dot {
  width: 3rem;
  height: 3rem;
  margin: 0 0.75rem;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  border: 0.2rem solid var(--color-grey-dark);
}
.carousel .pagination .dot:last-child {
  border-color: var(--color-green-dark);
}
.carousel .pagination .dot.active {
  background-color: var(--color-grey-dark);
  cursor: default;
}
.carousel .pagination .dot.active:last-child {
  background-color: var(--color-green-dark);
}

@media (prefers-color-scheme: dark) {
  .carousel .pagination {
    background-color: var(--color-black);
  }
  .carousel .pagination .dot {
    border-color: var(--color-grey-light);
  }
  .carousel .pagination .dot:last-child {
    border-color: var(--color-green-light);
  }
  .carousel .pagination .dot.active {
    background-color: var(--color-grey-light);
  }
  .carousel .pagination .dot.active:last-child {
    background-color: var(--color-green-light);
  }
}

@media screen and (min-width: 768px) {
  .carousel h1,
  .carousel .h1 {
    padding-right: 0;
  }
  .carousel .pagination {
    padding: 2rem;
  }
  .carousel .pagination .dot {
    width: 2rem;
    height: 2rem;
    margin: 0 0.5rem;
  }
}

.footer .wrapper {
  padding: 3rem 2rem;
  border-top: 1px solid var(--color-grey-light);
}
.footer-nav ul {
  list-style: none;
  text-align: center;
}
.footer-nav li {
  margin: 0 0 0.5rem 0;
}
.footer-nav a {
  display: block;
  padding: 1rem;
  font-weight: 600;
  text-decoration: none;
}
.footer-nav a:hover {
  text-decoration: underline;
}
.footer-legal {
  margin: 2rem 0 0;
  text-align: center;
}

.debug .footer {
  display: none;
}
.debug-version {
  position: absolute;
  z-index: 10;
  top: 0.25rem;
  right: -1rem;
  font-size: 1rem;
  transform: translate3d(-50%, 0, 0);
}


@media (prefers-color-scheme: dark) {
  .footer .wrapper {
    border-top-color: var(--color-grey-dark);
  }
}

@media screen and (min-width: 768px) {
  .footer .wrapper,
  .footer-nav ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-count: auto;
  }
  .footer-nav li {
    margin: 0 1.5rem 0 0;
  }
  .footer-legal {
    margin: 0;
  }
  .debug-version {
    top: 0.5rem;
    right: 0.5rem;
    font-size: 1.5rem;
    transform: translate3d(0, 0, 0);
  }
}
